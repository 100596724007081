import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { getAccountingId } from 'src/api/api-local';
import { Product, ProductService } from 'src/api/api.services';
import { StockComponent } from '../stock/stock.component';

@Component({
  selector: 'app-stock-form',
  templateUrl: './stock-form.component.html',
  styleUrls: ['./stock-form.component.css']
})
export class StockFormComponent implements OnInit {

  public form: FormGroup = this.formBuilder.group({
    productName: [null, Validators.required],
    quantity: [0, [Validators.required, Validators.min(1)]],
  });

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<StockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  openSnackBar(message: string, className: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
      panelClass: [className]
    });
  }

  submit() {
    var isCorrect: boolean = false;
    var product: Product = new Product({
      name: this.form.value.productName,
      quantity: this.form.value.quantity,
      accountingId: getAccountingId(),
      stockId: this.data
    }); 

    this.productService.productPost(product).subscribe(res => {
      isCorrect = true;
      this.openSnackBar("Produit ajouté", "snackbarCorrect")
      this.dialogRef.close(isCorrect);
    }, err => this.openSnackBar("Erreur", "snackbarError"));
  }

}
