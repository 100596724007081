import { Injectable, InjectionToken } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApplicationUserService, UserDto } from "src/api/api.services";
import { pipe } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  readonly BaseURI = environment.apiUrl + "/api";
  public userDto: UserDto = null;

  formModel = this.fb.group({
    UserName: ["", Validators.required],
    Email: ["", Validators.email],
    FullName: [""],
    Passwords: this.fb.group(
      {
        Password: ["", [Validators.required, Validators.minLength(4)]],
        ConfirmPassword: ["", Validators.required],
      },
      { validator: this.comparePasswords }
    ),
  });

  get IsLoggedIn(): boolean {
    return localStorage.getItem("token") != undefined;
  }

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private applicationUserService: ApplicationUserService,
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient
  ) {
    translate.setDefaultLang("en");
  }

  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get("ConfirmPassword");
    if (
      confirmPswrdCtrl.errors == null ||
      "passwordMismatch" in confirmPswrdCtrl.errors
    ) {
      if (fb.get("Password").value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else confirmPswrdCtrl.setErrors(null);
    }
  }

  logout() {
    localStorage.removeItem("token");
    this.router.navigate(["/user/login"]);
  }

  setLoginToken(loginResult) {
    localStorage.setItem("token", loginResult.token);

    this.applicationUserService.profile().subscribe(
      (res) => {
        this.userDto = res;
      },
      (err) => console.log(err)
    );
  }

  login(formData) {
    return this.http.post(this.BaseURI + "/ApplicationUser/Login", formData);
  }
}
