import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/api-authorization/user/user.service';
import { UserDto, Accounting, AccountingService, ApplicationUserService } from 'src/api/api.services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ChangeAccountingComponent } from './change-accounting/change-accounting.component';
import { getAccountingId } from 'src/api/api-local';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
},
{
    path: '/orders',
    title: 'Commandes',
    type: 'sub',
    icontype: 'view_agenda',
    collapse: 'Commandes',
    children: [
        { path: 'deliveries', title: 'Ventes', ab: 'V' },
        { path: 'purchases', title: 'Achats', ab: 'A' },
    ]
},
{
    path: '/management',
    title: 'Gestion',
    type: 'sub',
    icontype: 'apps',
    collapse: 'Gestion',
    children: [
        { path: 'clients', title: 'Clients', ab: 'C' },
        { path: 'payments', title: 'Paiements', ab: 'P' },
        { path: 'furnishers', title: 'Fournisseurs', ab: 'F' },
        { path: 'expenses', title: 'Dépenses', ab: 'D' },
        { path: 'stock', title: 'Stock', ab: 'S' }
    ]
},
    // {
    //     path: '/finance',
    //     title: 'Finances',
    //     type: 'sub',
    //     icontype: 'list',
    //     collapse: 'Finances',
    //     children: [
    //         { path: 'payments', title: 'Paiements', ab: 'P' }
    //     ]
    // }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html'
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public ps: any;
    public accounting: Accounting;

    constructor(
        private translate: TranslateService,
        private userService: UserService,
        private applicationUserService: ApplicationUserService,
        private accountingService: AccountingService,
        public dialog: MatDialog,
    ) {
        translate.setDefaultLang('en');
    }

    changeAccounting() {
        this.dialog.open(ChangeAccountingComponent, {
            width: '450px',
            disableClose: false
        });
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    getListAccountingd() {
        this.accountingService.accounting().subscribe(res => {
            localStorage.setItem('accountingId', res[0].accountingId.toString());
            this.accounting = res[0];
        }, err => console.log(err));
    }

    ngOnInit() {
        let aid = getAccountingId();
        if (aid) {
            this.accountingService.byId(aid).subscribe(res => {
                if (res) {
                    this.accounting = res;
                }
                else {
                    this.getListAccountingd();
                }
            }, err => console.log(err));
        }
        else {
            this.getListAccountingd();
        }

        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logout() {
        this.userService.logout();
    }
}
