import { BaseApiUrl, ApplicationUserService } from '../api/api.services';
import { UserService } from './../api-authorization/user/user.service';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { registerLocaleData } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthorizationInterceptor } from 'src/api-authorization/authorization.interceptor';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthenticationComponent } from './authentication/authentication.component';
import { MaterialModule } from './material.module';
import { CommonSharedModule } from './common-shared/common.module';
import localeFr from '@angular/common/locales/fr';
import { NgxCurrencyModule } from "ngx-currency";
import { customCurrencyMaskConfig } from './app.currency-mask';
import { StockFormComponent } from './management/stock-form/stock-form.component';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material';
import { ClientFormComponent } from './management/client-form/client-form.component';
import { FurnisherFormComponent } from './management/furnisher-form/furnisher-form.component';
import { ClientEditComponent } from './management/client-edit/client-edit.component';
import { FurnisherEditComponent } from './management/furnisher-edit/furnisher-edit.component'; registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  imports: [
    CommonSharedModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    HttpModule,
    MaterialModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    HttpClientModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ToastrModule.forRoot({
      progressBar: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    UserService,
    ApplicationUserService,
    { provide: BaseApiUrl, useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AuthenticationComponent,
    StockFormComponent,
    ClientFormComponent,
    FurnisherFormComponent,
    ClientEditComponent,
    FurnisherEditComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}