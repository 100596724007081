import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthorizationGuard } from 'src/api-authorization/authorization.guard';
import { RoleGuardService } from 'src/api-authorization/role.guard';
import { AuthenticationComponent } from './authentication/authentication.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'user',
    component: AuthenticationComponent,
    children: [
      {
        path: '',
        loadChildren: './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'management',
        loadChildren: './management/management.module#ManagementModule',
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'clients',
        loadChildren: './clients/clients.module#ClientsModule',
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'furnishers',
        loadChildren: './furnishers/furnishers.module#FurnishersModule',
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'orders',
        loadChildren: './orders/orders.module#OrdersModule',
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'finance',
        loadChildren: './finance/finance.module#FinanceModule',
        canActivate: [AuthorizationGuard]
      }
    ]
  }
];
