import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsComponent } from '../clients/clients.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService, Customer } from 'src/api/api.services';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css']
})
export class ClientEditComponent implements OnInit {

  public customer: Customer;
  private customerId: any;

  public form: FormGroup = this.formBuilder.group({
    companyName: [null, Validators.required],
    firstName: [null],
    lastName: [null],
    companyIdentificationNumber: [null],
    address: [null],
    contact: [null],
  });

  constructor(
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClientsComponent>,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.customerId = this.data;
    this.customerService.getCustomer(this.customerId).subscribe(res => {
      this.customer = res;
      this.form = this.formBuilder.group({
        companyName: [this.customer.companyName, Validators.required],
        firstName: [this.customer.firstName],
        lastName: [this.customer.lastName],
        companyIdentificationNumber: [this.customer.companyIdentificationNumber],
        address: [this.customer.address],
        contact: [this.customer.contact],
      });
    });

  }

  openSnackBar(message: string, className: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
      panelClass: [className]
    });
  }

  submit() {
    var isCorrect: boolean = false;
    var iceNumber = this.form.value.companyIdentificationNumber;
    var companyName = this.form.value.companyName;
    var firstName = this.form.value.firstName;
    var lastName = this.form.value.lastName;
    var address = this.form.value.address;
    var contactF = this.form.value.contact;
    if (companyName != null) {
      companyName = companyName.toUpperCase();
    }
    if (iceNumber != null) {
      if (iceNumber.length == 0) {
        iceNumber = null;
      }else{
        iceNumber = iceNumber.toUpperCase();
      }
    }
    if (firstName != null) {
      if (firstName.length == 0) {
        firstName = null;
      } else {
        firstName = firstName.toUpperCase();
      }
    }
    if (lastName != null) {
      if (lastName.length == 0) {
        lastName = null;
      } else {
        lastName = lastName.toUpperCase();
      }
    }
    if (address != null) {
      if (address.length == 0) {
        address = null;
      } else {
        address = address.toUpperCase();
      }
    }
    if (contactF != null) {
      if (contactF.length == 0) {
        contactF = null;
      }
    }
    var customer: Customer = new Customer({
      customerId: this.customerId,
      companyName: companyName,
      firstName: firstName,
      lastName: lastName,
      companyIdentificationNumber: iceNumber,
      address: address,
      contact: contactF,
      creationDate: this.customer.creationDate,
      createdByUserId: this.customer.createdByUserId
    });

    this.customerService.customerPut(customer).subscribe(res => {
      isCorrect = true;
      this.openSnackBar("Client modifié", "snackbarCorrect")
      this.dialogRef.close(isCorrect);
    }, err => this.openSnackBar("Erreur", "snackbarError"));
  }

}
