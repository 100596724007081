import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { DirhamCurrencyPipe } from './dirham-currencypipe';
import { SelectAccountingComponent } from './select-accounting/select-accounting.component';


@NgModule({
  declarations:[DirhamCurrencyPipe, SelectAccountingComponent],
  imports:[CommonModule],
  exports:[DirhamCurrencyPipe]
})

export class CommonSharedModule{}