import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { MatButtonModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from 'src/api-authorization/user/user.service';
import { AccountingService } from 'src/api/api.services';
import { MaterialModule } from 'src/app/material.module';
@NgModule({
    imports: [ RouterModule, CommonModule, TranslateModule, MaterialModule ],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent ],
    providers: [ UserService, AccountingService ]
})

export class NavbarModule { }
