import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogCrudResult, getAccountingId } from 'src/api/api-local';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Accounting, AccountingService } from 'src/api/api.services';

@Component({
  selector: 'app-change-accounting',
  templateUrl: './change-accounting.component.html',
  styleUrls: ['./change-accounting.component.css']
})
export class ChangeAccountingComponent implements OnInit {
  public accountings: Accounting[];

  public form: FormGroup = this.formBuilder.group({
    accountingId: [getAccountingId()]
  });

  constructor(
    private formBuilder: FormBuilder,
    private accountingService: AccountingService,
    public dialogRef: MatDialogRef<ChangeAccountingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogCrudResult
  ) { }

  ngOnInit(): void {
    this.accountingService.accounting().subscribe(res => {
      this.accountings = res;
      this.form.get('accountingId').setValue(+this.accountings.filter(a => a.accountingId == getAccountingId())[0].accountingId);
    }, err => console.log(err));
  }

  submit() {
    localStorage.setItem('accountingId', this.form.value.accountingId.toString());
    window.location.reload();
  }
}
