import { Component, OnInit } from '@angular/core';
import { FurnisherService, Furnisher } from 'src/api/api.services';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FurnishersComponent } from '../furnishers/furnishers.component';
import { getAccountingId } from 'src/api/api-local';

@Component({
  selector: 'app-furnisher-form',
  templateUrl: './furnisher-form.component.html',
  styleUrls: ['./furnisher-form.component.css']
})
export class FurnisherFormComponent implements OnInit {

  public form: FormGroup = this.formBuilder.group({
    companyName: [null, Validators.required],
    firstName: [null],
    lastName: [null],
    companyIdentificationNumber: [null],
    address: [null],
    contact: [null],
  });

  constructor(
    private customerService: FurnisherService,
    public dialogRef: MatDialogRef<FurnishersComponent>,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  openSnackBar(message: string, className: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
      panelClass: [className]
    });
  }

  submit() {
    var isCorrect: boolean = false;
    var iceNumber = this.form.value.companyIdentificationNumber;
    var companyName = this.form.value.companyName;
    var firstName = this.form.value.firstName;
    var lastName = this.form.value.lastName;
    var addressF = this.form.value.address;
    var contactF = this.form.value.contact;
    if (companyName != null) {
      companyName = companyName.toUpperCase();
    }
    if (iceNumber != null) {
      if (iceNumber.length == 0) {
        iceNumber = null;
      } else {
        iceNumber = iceNumber.toUpperCase();
      }
    }
    if (firstName != null) {
      if (firstName.length == 0) {
        firstName = null;
      } else {
        firstName = firstName.toUpperCase();
      }
    }
    if (lastName != null) {
      if (lastName.length == 0) {
        lastName = null;
      } else {
        lastName = lastName.toUpperCase();
      }
    }
    if (addressF != null) {
      if (addressF.length == 0) {
        addressF = null;
      } else {
        addressF = addressF.toUpperCase();
      }
    }
    if (contactF != null) {
      if (contactF.length == 0) {
        contactF = null;
      }
    }
    var furnisher: Furnisher = new Furnisher({
      companyName: companyName,
      firstName: firstName,
      lastName: lastName,
      companyIdentificationNumber: iceNumber,
      address: addressF,
      contact: contactF,
    });

    this.customerService.furnisherPost(furnisher).subscribe(res => {
      isCorrect = true;
      this.openSnackBar("Fournisseur ajouté", "snackbarCorrect")
      this.dialogRef.close(isCorrect);
    }, err => this.openSnackBar("Erreur", "snackbarError"));
  }

}
