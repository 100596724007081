import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { MatButtonModule, MatInputModule, MatFormFieldModule } from '@angular/material';
import { AccountingService, ApplicationUserService } from 'src/api/api.services';
import { ChangeAccountingComponent } from './change-accounting/change-accounting.component';

@NgModule({
    imports: [ RouterModule, CommonModule, TranslateModule, MaterialModule, MatInputModule, MatFormFieldModule ],
    declarations: [ SidebarComponent, ChangeAccountingComponent ],
    exports: [ SidebarComponent ],
    providers: [ AccountingService, ApplicationUserService ],
    entryComponents: [ChangeAccountingComponent]
})

export class SidebarModule {}
