export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "DH ",
    suffix: "",
    thousands: " ",
    nullable: true
};