import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(private router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clonedReq = req.clone({
            headers: req.headers.set('TimeZone-Offset', `${new Date().getTimezoneOffset()}`),
        });
        if (localStorage.getItem('token') != null) {
            let clonedAuthReq = req.clone({
                headers: clonedReq.headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'))
            });

            return next.handle(clonedAuthReq).pipe(
                tap(
                    succ => { },
                    err => {
                        if (err.status == 401) {
                            localStorage.removeItem('token');
                            this.router.navigate(['/user/login']);
                        }
                    }
                )
            )
        }
        else {
            return next.handle(clonedReq);
        }
    }
}