import { Injectable } from "@angular/core";

// @Injectable()
// export class UserService {
// }

export class User {
    userId: number;
    email: string;
    password: string;
    role: UserRoleEnum;
    firstName: string;
    lastName: string;
}

export enum UserRoleEnum {
    Admin = "Admin",
    Commercial = "Commercial"
}

export enum DialogCrudAction {
    None,
    Add,
    Modify,
    Remove
}

export class DialogCrudResult {
    action: DialogCrudAction;
    data: any;
    stockId: number | null;
    currency: string;
}

export function getAccountingId(): number {
    return +localStorage.getItem('accountingId');
}

export class DataRowClicked {
    index: number;
    row: any;
}